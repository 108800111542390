<template>
  <div class="app-container">
    <breadcrumb-nav>
      <template v-slot:firstMenu>订单管理</template>
      <template v-slot:secondMenu>发货信息</template>
    </breadcrumb-nav>
    <el-card>
    <div class="table-container">
      <div class="table-title">订单商品信息</div>
      <el-table class="custom-order-table" :data="list" border stripe>
        <el-table-column label="商品图标">
          <template slot-scope="scope">
            <el-image
              style="height: 80px; width: 120px"
              :src="scope.row.image"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="shopName" label="店铺"></el-table-column>
        <el-table-column prop="goodsName" label="商品名称"></el-table-column>
        <el-table-column prop="skuDesc" label="规格信息"></el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">{{ scope.row.createTime | dateFormat }}</template>
        </el-table-column>
        <el-table-column prop="number" label="商品数量"></el-table-column>
        <el-table-column prop="price" label="商品价格" width="120px">
          <template slot-scope="scope">{{ scope.row.price | floatFormat }}</template>
        </el-table-column>
        <el-table-column prop="refundNum" label="状态" width="120px">
          <template slot-scope="scope">{{ scope.row.refundNum | formatRefundStatus }}</template>
        </el-table-column>
      </el-table>
      <div class="table-title-btm">快递地址信息</div>
      <el-table ref="deliverOrderTable"
                style="width: 100%;"
                :data="list" border>
        <el-table-column label="订单编号" width="180" align="center">
          <template slot-scope="scope">{{scope.row.orderSn}}</template>
        </el-table-column>
        <el-table-column label="收货人" width="100" align="center">
          <template slot-scope="scope">{{scope.row.consignee}}</template>
        </el-table-column>
        <el-table-column label="手机号码" width="120" align="center">
          <template slot-scope="scope">{{scope.row.mobile}}</template>
        </el-table-column>
        <!--<el-table-column label="收货地址" align="center">
          <template slot-scope="scope">{{scope.row.address}}</template>
        </el-table-column>-->
        <el-table-column label="详细地址" align="center">
          <template slot-scope="scope">{{scope.row.fullAddr}}</template>
        </el-table-column>
        <el-table-column label="快递公司" width="160" align="center">
          <template slot-scope="scope">
            <el-select placeholder="请选择快递公司"
                       v-model="scope.row.deliveryCompany"
                       size="small">
              <el-option v-for="item in expressList"
                         :key="item.id"
                         :label="item.name"
                         :value="`${item.name},${item.code}`">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="快递单号" width="180" align="center">
          <template slot-scope="scope">
            <el-input size="small" v-model="scope.row.deliverySn"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 15px;text-align: center">
        <el-button @click="cancel">返回</el-button>
        <el-button v-show="orderStatus===1" @click="send" type="success">待揽件</el-button>
        <el-button @click="confirm" type="primary">提交快递信息</el-button>
      </div>
    </div>
    </el-card>
  </div>
</template>
<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { deliveryOrder, getOrderDetail, updateOrderStatus } from '../../../network/order'
import { getExpressList } from '../../../network/goods'
// const defaultLogisticsCompanies = ['顺丰快递', '圆通快递', '中通快递', '韵达快递']
export default {
  name: 'OrderDelivery',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      orderId: 0,
      orderStatus: 0,
      list: [],
      expressList: [],
      downloadUrlPrefix: ''
    }
  },
  filters: {
    formatRefundStatus (value) {
      if (value > 0) {
        return '已退款'
      } else {
        return '正常'
      }
    }
  },
  created () {
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
    this.orderId = parseInt(this.$route.query.orderId)
    getOrderDetail({ orderId: this.orderId }).then(res => {
      if (res.status !== 200) {
        return this.alertMessage('获取订单明细失败', 'error')
      }
      this.orderStatus = res.data.data.orderStatus
      this.list = res.data.data.goodsList
      for (const item of this.list) {
        if (item.icon.includes('http')) {
          item.image = item.icon
        } else {
          item.image = this.downloadUrlPrefix + item.icon
        }
        // item.deliveryCompany = res.data.data.deliveryCompany
        // item.deliverySn = res.data.data.deliverySn
        item.address = res.data.data.address
        item.consignee = res.data.data.consignee
        item.mobile = res.data.data.mobile
        item.fullAddr = res.data.data.province + res.data.data.city + res.data.data.district + item.address + ' ' + item.consignee + ' ' + item.mobile
      }
    })
    getExpressList().then(res => {
      const result = res.data
      if (res.status !== 200) {
        return this.alertMessage('获取列表失败', 'error')
      }
      this.expressList = result.data.list
    })
  },
  methods: {
    cancel () {
      this.$router.back()
      /* this.$message({
        type: 'info',
        message: '已取消发货'
      }) */
    },
    send () {
      updateOrderStatus({ status: 2, orderId: this.orderId }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('发货待揽件提交失败', 'error')
        }
        this.$router.back()
        this.$message({
          type: 'success',
          message: '发货待揽件提交成功!'
        })
      })
    },
    confirm () {
      this.$confirm('是否要进行发货操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('----this.list:', this.list)
        if (this.list.length <= 0) {
          return this.alertMessage('发货失败, 没有发货信息', 'error')
        }
        const delivery = { list: [], status: 2 } // 已发货，待收货
        for (const item of this.list) {
          if (!item.deliveryCompany) {
            return this.alertMessage('发货失败,请选择快递公司', 'error')
          }
          const dv = item.deliveryCompany.split(',')
          if (dv.length < 2) {
            return this.alertMessage('发货失败,请选择快递公司', 'error')
          }
          if (!item.deliverySn || item.deliverySn.length <= 0) {
            return this.alertMessage('发货失败,请填写快递单号', 'error')
          }
          delivery.deliverySn = item.deliverySn
          delivery.deliveryCompany = dv[0]
          delivery.deliveryCode = dv[1]
          delivery.orderId = item.orderId
          delivery.list.push({
            expressNo: item.deliverySn,
            expressName: dv[0],
            expressCode: dv[1],
            orderId: item.orderId,
            goodsId: item.goodsId,
            skuId: item.skuId
          })
        }
        console.log('delivery:', delivery)
        // eslint-disable-next-line no-unreachable
        deliveryOrder(delivery).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('提交快递信息失败', 'error')
          }
          this.$router.back()
          this.$message({
            type: 'success',
            message: '提交快递信息失败!'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交快递信息'
        })
      })
    }
  }
}
</script>
<style>
.table-title{
  margin-bottom: 5px;
}
.table-title-btm{
  margin-top: 20px;
  margin-bottom: 5px;
}
</style>
